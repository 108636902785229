import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Sergio Gallegos</h1>
      </header>

      <section id="about" className="App-section">
        <div className="container">
          <h2>About Me</h2>
          <p>
            I am a Programmer possessing a Bachelor's degree in Electromechanical Engineering, currently engaged as a Controls Engineer with a focus on PLC programming. Simultaneously, I am dedicated to advancing my skills in software engineering through self-directed learning and exploration, driven by a passion for innovation and excellence in the field.
          </p>
          <p>
            My academic and professional pursuits revolve around various domains, including automation machinery manufacturing, software development, machine learning, robotics, system programming, and web development.
          </p>
        </div>
      </section>

      <section id="articles" className="App-section">
        <div className="container">
          <h2>Articles</h2>
          <div className="article">
            <iframe 
              src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7233251967311982595" 
              height="400" 
              width="100%" 
              frameborder="0" 
              allowfullscreen="" 
              title="Embedded post 1"
            ></iframe>
          </div>
          <div className="article">
            <iframe 
              src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7233266601226035200" 
              height="400" 
              width="100%" 
              frameborder="0" 
              allowfullscreen="" 
              title="Embedded post 2"
            ></iframe>
          </div>
        </div>
      </section>

      <footer className="App-footer">
        <div className="footer-container">
          <div className="footer-info">
            <p>&copy; 2024 Sergio Gallegos. All rights reserved.</p>
          </div>
          <nav>
            <ul>
              <li><a href="https://www.linkedin.com/in/sergio-gallegos-24271a66/" target="_blank" rel="noopener noreferrer">
                LinkedIn
              </a></li>
              <li><a href="https://twitch.com/imsergiog" target="_blank" rel="noopener noreferrer">
                Twitch
              </a></li>
              <li><a href="https://www.youtube.com/@imsergiog" target="_blank" rel="noopener noreferrer">
                YouTube
              </a></li>
              <li><a href="https://twitter.com/sergiogallegosh" target="_blank" rel="noopener noreferrer">
                X
              </a></li>
              <li><a href="https://github.com/sergiogallegos" target="_blank" rel="noopener noreferrer">
                GitHub
              </a></li>
            </ul>
          </nav>
        </div>
      </footer>
    </div>
  );
}

export default App;
